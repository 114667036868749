<template>
  <span v-if="id">
    <i @click="showModal" v-b-modal:modal-update-sms-template style="color: #7367f0"
      class="action-button icon-eye mr-2"></i>
    <b-modal id="modal_details" ok-variant="info" hide-footer no-close-on-backdrop>
      <template #modal-header="{ close }">
        <h5>{{ $t('withdrawal.withdrawal_details') }}</h5>
        <i class="fas fa-times hover-icon action-button" @click="closeModal()"></i>
      </template>
      <div class="">
        <b-row>
          <b-col class="label">
            {{ $t('withdrawal.withdrawal_status') }}
          </b-col>
          <b-col align="end">
            {{ withdrawalStatus }}
            {{ previewDetails?.status }}
            <b-badge pill class="px-2 badge-status" :class="`${colorButton(previewDetails?.status)}`">
              {{ filterLangValue(withdrawalStatus, previewDetails?.status) || '-' }}
            </b-badge>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('messageText.note') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            ระบบจะทำการส่ง SMS หรือ E-mail เพื่อแจ้งเตือนการโอนทุกวันที่ 10 ของทุกเดือน
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="line-dotted mt-2 mb-2"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <div class="flex-center label">
              <span v-if="previewDetails?.customer_type !== 'juristic'">
                {{ $t('account.verify_account.first_name') }}/{{ $t('account.verify_account.last_name') }}
              </span>
              <span v-else>
                {{ $t('account.verify_account.company_name') }}
              </span>
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.name }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('account.verify_account.tax_id') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.tax_id }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('subscription.checkout.phone') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.phone }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('subscription.checkout.email') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.email }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('subscription.checkout.address') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ changeFormatAddress(previewDetails?.address) }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="line-dotted mt-2 mb-2"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('subscription.checkout.bank_method') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.customer_bank.account_name }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('withdrawal.account_number') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.customer_bank.account_no }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('withdrawal.bank') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right">
            {{ previewDetails?.customer_bank.bank }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="line-dotted mt-2 mb-2"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('withdrawal.value_of_withdrawal') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right font-weight-700" style="color: #161E54;">
            {{ addCommaAndPrice(previewDetails?.amount) }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('subscription.checkout.vat') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right font-weight-700" style="color: #5C698C;">
            +{{ addCommaAndPrice(previewDetails?.vat_7_percent) }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('subscription.checkout.with_holding_tax') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right font-weight-700" style="color: #00927F;">
            -{{ addCommaAndPrice(previewDetails?.withholding_tax_3_percent) }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <div class="flex-center label">
              {{ $t('withdrawal.summary_withdrawal_transfer') }}
            </div>
          </b-col>
          <b-col cols="8" class="text-right font-weight-700" style="color: #161E54;">
            {{ addCommaAndPrice(previewDetails?.net_balance) }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="line-dotted mt-2 mb-2"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <div class="label mt-2">{{ $t('withdrawal.summary_withdrawal_result') }}</div>
            <div style="font-size: 36px; font-weight: 700; color: #00927F;">
              {{ addCommaAndPrice(previewDetails?.net_balance) }}
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="line-dotted mt-2 mb-2"></div>
          </b-col>
        </b-row>
        <b-row>
          <div class="flex-middle mt-4 mb-3">
            <button type="button" class="btn waves-effect waves-light btn-gradient" @click="closeModal()">
              <div style="min-width: 80px;">
                {{ $t('button.close') }}
              </div>
            </button>
          </div>
        </b-row>
      </div>
    </b-modal>
  </span>
</template>

<script>
import masterData from '@/common/masterData.json'
import functions from "@/common/functions";
import affiliateApi from "@/repository/affiliateApi";

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      response_created: {
        title: 'Title',
        short_link: 'Short Link'
      },
      withdrawalStatus: masterData.withdrawalStatus,
      previewDetails: {
        status: "",
        address: {
          address: '',
          sub_district: '',
          district: '',
          province: '',
          zipcode: ''
        },
        amount: 0,
        brand_id: "",
        codeSYS: "",
        customer_bank: {
          account_name: '',
          account_no: '',
          bank: '',
          book_bank: ''
        },
        customer_type: "",
        customer_id: "",
        email: "",
        group_id: "",
        member_id: "",
        name: "",
        net_balance: 0,
        phone: "",
        tax_id: "",
        transaction_period: "",
        vat_7_percent: 0,
        withdraw_date: "",
        withholding_tax_3_percent: 0
      }
    }
  },
  methods: {
    clearPreviewData() {
      console.log('clearPreviewData');
      this.previewDetails = {
        status: "",
        address: {
          address: '',
          sub_district: '',
          district: '',
          province: '',
          zipcode: ''
        },
        amount: 0,
        brand_id: "",
        codeSYS: "",
        customer_bank: {
          account_name: '',
          account_no: '',
          bank: '',
          book_bank: ''
        },
        customer_type: "",
        customer_id: "",
        email: "",
        group_id: "",
        member_id: "",
        name: "",
        net_balance: 0,
        phone: "",
        tax_id: "",
        transaction_period: "",
        vat_7_percent: 0,
        withdraw_date: "",
        withholding_tax_3_percent: 0
      }
    },
    // submit(text) {
    //   console.log(text);
    // },
    addCommaAndPrice(data) {
      return `฿${data ? data.toLocaleString() : 0}`
    },
    changeFormatAddress(data) {
      const address = data?.address ? data?.address : ''
      const sub_district = data?.sub_district ? 'แขวง' + data?.sub_district : ''
      const district = data?.district ? 'เขต' + data?.district : ''
      const province = data?.province ? data?.province : ''
      const zipcode = data?.zipcode ? data?.zipcode : ''
      return address + ' ' + sub_district + ' ' + district + ' ' + province + ' ' + zipcode
    },
    showModal() {
      this.clearPreviewData();
      affiliateApi.getRequestDetails(this.id).then((res) => {
        setTimeout(() => {
          this.previewDetails = res
          console.log(this.previewDetails)
          console.log(this.previewDetails.status)
          this.$bvModal.show('modal_details');
        }, 30);
      }).catch((err) => {
        console.log(err)
        alert.resendFailed(this, err.message)
      })
    },
    closeModal() {
      this.$bvModal.hide('modal_details');
    },
    colorButton(status) {
      switch (status) {
        case 'pending':
          return 'orange';
        case 'processing':
          return 'yellow'
        case 'rejected':
          return 'gray'
        case 'success':
          return 'green'
        case 'failed':
          return 'red'
        case 'cancelled':
          return 'hide'
        default:
          return null

      }
    },
    filterLangValue(arr, val) {
      return functions.filterMasterData(arr, val, this.$i18n.locale)
    },
  }
}
</script>

<style lang="scss" scoped>
$bg-status-orange: #FE7A00;
$bg-text-orange: #fff;
$bg-status-yellow: #FFF7AF;
$bg-text-yellow: #E3B100;
$bg-status-gray: #E8E8E8;
$bg-text-gray: #555555;
$bg-status-green: #DAFFF2;
$bg-text-green: #00927F;
$bg-status-red: #FFDADA;
$bg-text-red: #D60000;
$bg-status-hide: #E8E8E8;
$bg-text-hide: #9F9F9F;

.badge-status {
  &.orange {
    background-color: $bg-status-orange;
    color: $bg-text-orange;
  }

  &.yellow {
    background-color: $bg-status-yellow;
    color: $bg-text-yellow;
  }

  &.gray {
    background-color: $bg-status-gray;
    color: $bg-text-gray;
  }

  &.green {
    background-color: $bg-status-green;
    color: $bg-text-green;
  }

  &.red {
    background-color: $bg-status-red;
    color: $bg-text-red;
  }

  &.hide {
    background-color: $bg-status-hide;
    color: $bg-text-hide;
  }
}

.button-withdraw {
  background-image: linear-gradient(140deg, #08656B 0%, #01111A 70%);
  color: #fff;
  border-radius: 18px;
  padding: 8px 16px;
  font-size: 20px;
  font-weight: 700;
  width: 200px;
  height: 94px;
  box-shadow: 6px 6px 17px #0000004D;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background-image: linear-gradient(140deg, #08656B 0%, #01111A 100%);
    box-shadow: 3px 3px 7px #0000004D;
    filter: brightness(1.3);
  }
}

.line-dotted {
  height: 0;
  width: 100%;
  border-top: 1px dotted #C5D1DE;
}
.label {
  font-size: 14px;
  color: #5C698C;
  font-weight: 700;

}
</style>

<style lang="scss">
#modal_details {
  h5 {
    margin-bottom: 0;
  }

  .modal-header {
    border-bottom: 1px solid #E9ECEF;
  }
}
</style>